<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <div>
          <small class="text-primary">{{ dataItem.gcode }}</small>
          <b-card-title>{{ dataItem.title }}</b-card-title>
        </div>
        <div>
          <small class="text-warning"><b>Çekiliş Kesinleniş: </b>{{ moment(dataItem.created).format('DD.MM.YYYY HH:MM:SS') }}</small>
        </div>
      </b-card-header>
      <b-card-body>
        <b-list-group>
          <b-list-group-item variant="">
            <h5 class="text-primary">
              Ödüller
            </h5>
            {{ dataItem.awards }}
          </b-list-group-item>
        </b-list-group>
        <b-row class="mt-2">
          <b-col>
            <b-list-group>
              <h5 class="text-primary">
                <feather-icon icon="UsersIcon" />
                Katılanlar
              </h5>
              <b-list-group-item
                v-for="(line,key) in dataItem.participants"
                :key="key"
              >
                {{ line }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col>
            <b-list-group>
              <h5 class="text-primary">
                <feather-icon icon="CheckCircleIcon" />
                Kazananlar
              </h5>
              <b-list-group-item
                v-for="(line,key) in dataItem.winners"
                :key="key"
              >
                {{ line }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'GiveAwayView',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['giveaways/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('giveaways/getDataItem', this.$route.params.id)
    },
  },
}
</script>
